import Coin from "./Coin";
import React, { useState } from "react";

const CONVERSION_SSD_RATE = 100;

const BundleItem = ({ amount, onAmountChange, isCustom=false, isEditable=false }) =>  {
  const [amountSsd, setAmountSsd] = useState(amount);

  const cleanSsd = (amountSsd) => {
    if (! /^[0-9]+/g.test(amountSsd)) {
      setAmountSsd("");
      return false;
    }
    const cleanedAmount = amountSsd.replace(/[^0-9]/g, "");
    if (!cleanedAmount || cleanedAmount <= 0) {
      setAmountSsd("");
      return false;
    }
    return cleanedAmount;
  }

  const calculateSsdAmountToUsd = (amount) => {
    return amount / CONVERSION_SSD_RATE;
  }

  const handleAmountChange = (e) => {
    const cleanedSsdAmount = cleanSsd(e.target.value);
    if (cleanedSsdAmount) {
      setAmountSsd(cleanedSsdAmount);
    }
    const usdAmount = calculateSsdAmountToUsd(cleanedSsdAmount);
    if (usdAmount) {
      // emit event to parent component
      onAmountChange(usdAmount, cleanedSsdAmount);
    }
  }

  return (
    <div className='bundle-purchase-amount-wrapper'>
      <Coin />
      <div>
        {
          isCustom && isEditable ? (
            <div className='bundle-purchase-amount-input-wrapper ml-2 mr-2'>
              <input type='text'
                className='bundle-purchase-amount-input'
                placeholder="Custom amount"
                value={amountSsd}
                onChange={handleAmountChange} />
            </div>
          ) : (
            isCustom ? (
              <p className='bundle-purchase-amount'>
                Custom
              </p>
            ) : (
              <p className='bundle-purchase-amount'>
                { amount }
              </p>
            )
          )
        }
      </div>
      <div>
        Sansar dollars
      </div>
    </div>
  )
}

export default BundleItem;
