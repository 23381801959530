import axios from "axios";
import { sleep } from "./apis";

const axiosClient = axios.create({
  baseURL: "https://ipapi.co",
  timeout: 5 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

const getUserBrowserInfo = async () => {
  let exponentialBackoff = 1000;
  while (true) {
    try {
      const response = await axiosClient.get("/json/");
      return {
        ip: response.data.ip,
        city: response.data.city,
        country: response.data.country,
        countryCode: response.data.country_code,
        countryName: response.data.country_name,
        currency: response.data.currency,
        inEu: response.data.in_eu,
        latitude: response.data.latitude,
        longitude: response.data.longitude,
        timezone: response.data.timezone,
        utcOffset: response.data.utc_offset,
      };
    } catch (error) {
      await sleep(exponentialBackoff);
      exponentialBackoff = exponentialBackoff * 2;
    }
  }
}

export {
  getUserBrowserInfo,
};
