import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BundleItem from "../components/BundleItem";
import { getBundles } from "../services/bundles";
import { buildCartItemFromBundle } from "../utils/cartItem";
import "../styles/bundles.css";

class Bundles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bundles: [],
    };
    this.proceedToPurchaseBundle = this.proceedToPurchaseBundle.bind(this);
  }

  async componentDidMount() {
    try {
      const bundles = await getBundles();
      this.setState({ bundles });
    } catch (error) {}
  }

  proceedToPurchaseBundle(bundle) {
    const { dispatch } = this.props;

    dispatch({ type: "cartItems/clean" });
    const quantity = bundle?.is_custom ? bundle.sansar_dollar_amount : 1;
    const cartItem = buildCartItemFromBundle(bundle, quantity);
    dispatch({ type: "cartItems/add", payload: cartItem });

    const totalPrice = parseFloat(bundle.price);
    dispatch({ type: "totalPrice/update", payload: totalPrice });

    this.props.history.push(`/purchase/${bundle.id}`);
  }

  render() {
    const { proceedToPurchaseBundle } = this;
    const { bundles } = this.state;

    return (
      <section>
        <h1>
          Buy Sansar dollars
        </h1>
        <p style={{ textAlign: "center" }}>
          The Sansar dollar is the currency used in Sansar to buy and sell virtual goods.<br/>
          How many Sansar dollars would you like to buy?
        </p>
        <div className='bundles'>
          {bundles.map((bundle) => (
            <div key={bundle.id} className='bundle'>
              <BundleItem
                amount={bundle.sansar_dollar_amount}
                onAmountChange={() => {}}
                isCustom={bundle?.is_custom}
                isEditable={false} />
              <button onClick={() => proceedToPurchaseBundle(bundle)}
                className='bundle-purchase-button dark-blue mt-2 mb-2'>
                <b>
                  { bundle?.is_custom ? "Custom" : bundle.formatted_price }
                </b>
              </button>
            </div>
          ))}
        </div>
      </section>
    )
  }
};

const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps)(withRouter(Bundles));
